<app-sv-page-content-header
  titulo="Matriculando a Nuevo Estudiante">
</app-sv-page-content-header>

<div class="page-content">
  <mat-horizontal-stepper [linear]="true" #stepper [formGroup]="formGroup">

    <mat-step label="Datos de Personas">
    <!-- <app-matri-proceso-main-paso1 formGroupName="data_paso_1" [xFormGroup]="personasGroup"></app-matri-proceso-main-paso1> -->
      <div>
        <button mat-button matStepperNext>Continuar</button>
      </div>
    </mat-step>

    <!-- <mat-step label="Selección de Grupo">
      <app-matri-proceso-main-paso2 formGroupName="data_paso_2"></app-matri-proceso-main-paso2>
      <div>
        <button mat-button matStepperNext>Continuar</button>
      </div>
    </mat-step>

    <mat-step label="Pago de Matrícula">
      <app-matri-proceso-main-paso3 formGroupName="data_paso_3"></app-matri-proceso-main-paso3>
      <div>
        <button mat-button>Matricular</button>
      </div>
    </mat-step> -->

  </mat-horizontal-stepper>
</div>
