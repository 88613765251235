import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-matri-proceso-nuevo-estudiante-pasos',
  templateUrl: './matri-proceso-nuevo-estudiante-pasos.component.html',
  styleUrl: './matri-proceso-nuevo-estudiante-pasos.component.css'
})
export class MatriProcesoNuevoEstudiantePasosComponent {

  formGroup: FormGroup = this.fb.group({});

  constructor(
    private fb: FormBuilder
  ) {
    this.formGroup = this.fb.group({
      data_paso_1: this.fb.group({
        personas: this.fb.array([]),
        tiene_familiar: [false],
      }),
      data_paso_2: this.fb.group({
        grupo: ['', Validators.required]
      }),
      data_paso_3: this.fb.group({
        metodoPago: ['', Validators.required],
        monto: ['', Validators.required]
      })
    });
  }

  ngOnInit() {
    // this.buildForm();
    this.setInitialData();
  }

  // buildForm() {
  //   this.formGroup = this.fb.group({
  //     data_paso_1: this.fb.group({
  //       personas: this.fb.array([]),
  //       tiene_familiar: [false],
  //     }),
  //     data_paso_2: this.fb.group({
  //       grupo: ['', Validators.required]
  //     }),
  //     data_paso_3: this.fb.group({
  //       metodoPago: ['', Validators.required],
  //       monto: ['', Validators.required]
  //     })
  //   });
  // }

  get personasArray(): FormArray {
    return this.formGroup.get('data_paso_1.personas') as FormArray;
  }
  get personasGroup(): FormGroup {
    return this.formGroup.get('data_paso_1') as FormGroup;
  }

  setInitialData() {
    const estudiante = this.fb.group({
      id_user: ['1'],
      name: ['Estudiante', Validators.required],
      name_2: ['Estudiante', Validators.required],
      lastname_1: ['ApellidoPaterno', Validators.required],
      lastname_2: ['ApellidoMaterno', Validators.required],
      email: ['estudiante@example.com', Validators.email],
      documento_identidad: ['12345678', Validators.required],
      tipo_documento: ['1', Validators.required],
      domicilio: ['Calle Estudiante 123', Validators.required],
      celular: ['123456789', Validators.required],
      relacion: ['estudiante', Validators.required]
    });

    const padre = this.fb.group({
      id_user: ['2'],
      name: ['Padre', Validators.required],
      name_2: ['Estudiante', Validators.required],
      lastname_1: ['ApellidoPaterno', Validators.required],
      lastname_2: ['ApellidoMaterno', Validators.required],
      email: ['padre@example.com', Validators.email],
      documento_identidad: ['87654321', Validators.required],
      tipo_documento: ['1', Validators.required],
      domicilio: ['Calle Padre 123', Validators.required],
      celular: ['987654321', Validators.required],
      relacion: ['padre', Validators.required]
    });

    this.personasArray.push(estudiante);
    this.personasArray.push(padre);

    console.log(this.formGroup.value);
  }


}
