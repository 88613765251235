<app-sv-page-content-header
  titulo="Matriculando a Nuevo Estudiante">
</app-sv-page-content-header>

<div class="page-content">
  <mat-horizontal-stepper [linear]="false" #stepper>
    <mat-step label="Datos del Alumno">
      <form [formGroup]="alumnoForm" (ngSubmit)="onSubmit()">
        <div class="form-row mt-2">
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>2do Nombre</mat-label>
            <input matInput formControlName="name_2">
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Apellido Paterno</mat-label>
            <input matInput formControlName="lastname_1" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Apellido Materno</mat-label>
            <input matInput formControlName="lastname_2" required>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Domicilio</mat-label>
            <input matInput formControlName="domicilio" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Celular</mat-label>
            <input matInput formControlName="celular" required>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Tipo de Documento</mat-label>
            <mat-select formControlName="tipo_documento" required>
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">{{ tipo.nombre }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-input">
            <mat-label>Documento de Identidad</mat-label>
            <input matInput formControlName="documento_identidad" required>
          </mat-form-field>
        </div>

      </form>
    </mat-step>

    <mat-step label="Datos de Parientes">
      <form [formGroup]="parentForm" (ngSubmit)="onSubmit()">
        <div class="form-row">
          <mat-checkbox formControlName="padre" (change)="onParentChange('padre')">Padre</mat-checkbox>
          <mat-checkbox formControlName="madre" (change)="onParentChange('madre')">Madre</mat-checkbox>
          <mat-checkbox formControlName="apoderado" (change)="onParentChange('apoderado')">Apoderado</mat-checkbox>
        </div>

        <div *ngIf="parentForm.get('padre')?.value">
          <h3>Datos del Padre</h3>
          <form formGroupName="padreForm" (ngSubmit)="onSubmit()">
            <div class="form-row mt-2">
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="name" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>2do Nombre</mat-label>
                <input matInput formControlName="name_2">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Apellido Paterno</mat-label>
                <input matInput formControlName="lastname_1" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Apellido Materno</mat-label>
                <input matInput formControlName="lastname_2" required>
              </mat-form-field>
            </div>
            <div class="form-row mt-2">
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Domicilio</mat-label>
                <input matInput formControlName="domicilio" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Tipo de Documento</mat-label>
                <mat-select formControlName="tipo_documento" required>
                  <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">{{ tipo.nombre }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Documento de Identidad</mat-label>
                <input matInput formControlName="documento_identidad" required>
              </mat-form-field>
            </div>
          </form>
        </div>

        <div *ngIf="parentForm.get('madre')?.value">
          <h3>Datos de la Madre</h3>
          <form formGroupName="madreForm" (ngSubmit)="onSubmit()">
            <div class="form-row mt-2">
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="name" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>2do Nombre</mat-label>
                <input matInput formControlName="name_2">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Apellido Paterno</mat-label>
                <input matInput formControlName="lastname_1" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Apellido Materno</mat-label>
                <input matInput formControlName="lastname_2" required>
              </mat-form-field>
            </div>
            <div class="form-row mt-2">
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Domicilio</mat-label>
                <input matInput formControlName="domicilio" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Tipo de Documento</mat-label>
                <mat-select formControlName="tipo_documento" required>
                  <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">{{ tipo.nombre }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Documento de Identidad</mat-label>
                <input matInput formControlName="documento_identidad" required>
              </mat-form-field>
            </div>
          </form>
        </div>

        <div *ngIf="parentForm.get('apoderado')?.value">
          <h3>Datos del Apoderado</h3>
          <form formGroupName="apoderadoForm" (ngSubmit)="onSubmit()">
            <div class="form-row mt-2">
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="name" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>2do Nombre</mat-label>
                <input matInput formControlName="name_2">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Apellido Paterno</mat-label>
                <input matInput formControlName="lastname_1" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Apellido Materno</mat-label>
                <input matInput formControlName="lastname_2" required>
              </mat-form-field>
            </div>
            <div class="form-row mt-2">
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Domicilio</mat-label>
                <input matInput formControlName="domicilio" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Celular</mat-label>
                <input matInput formControlName="celular" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Tipo de Documento</mat-label>
                <mat-select formControlName="tipo_documento" required>
                  <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">{{ tipo.nombre }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-input">
                <mat-label>Documento de Identidad</mat-label>
                <input matInput formControlName="documento_identidad" required>
              </mat-form-field>
            </div>
          </form>
        </div>

      </form>
    </mat-step>
    <!-- Icon overrides. -->
    <ng-template matStepperIcon="phone">
      <mat-icon>call_end</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="chat">
      <mat-icon>forum</mat-icon>
    </ng-template>
  </mat-horizontal-stepper>

  <div class="row gx-0">
    <button class="btn-status btn-primary text-center" (click)="onSubmit()">Guardar datos y continuar</button>
  </div>

</div>
