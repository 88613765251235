import { ApplicationRef, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { SvPdfInvoiceA4Component } from './sv-pdf-invoice-a4/sv-pdf-invoice-a4.component';

@Injectable({
  providedIn: 'root'
})
export class SharedComponentsService {
  private viewContainerRef: ViewContainerRef | null = null;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}



  ////////////////////////////////////////////////////////////
  /////////////////////       APIS       /////////////////////
  ////////////////////////////////////////////////////////////

  // apiGetModulosMenu = (params: any)=> this.http.get(this.apiUrl+'/configuration/modulos-home-user', params);
  // apiGetModulosMenu = ()=> this.http.get(this.apiUrl+'/configuration/modulos-home-user').pipe(map((r: any) => r.data));





  ////////////////////////////////////////////////////////////
  /////////////////////       PDFs       /////////////////////
  ////////////////////////////////////////////////////////////

  setViewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  // printComponent(title: string, content: string) {
  //   if (!this.viewContainerRef) {
  //     console.error('ViewContainerRef is not set!');
  //     return;
  //   }

  //   // Crear el componente dinámicamente
  //   const componentRef: ComponentRef<SvPdfInvoiceA4Component> = this.viewContainerRef.createComponent(SvPdfInvoiceA4Component, {
  //     injector: this.injector
  //   });

  //   componentRef.instance.title = title;
  //   componentRef.instance.content = content;

  //   // Attach the component to the application so that change detection works
  //   this.appRef.attachView(componentRef.hostView);

  //   // Get the DOM element of the component
  //   const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;

  //   // Open a new window and write the component's HTML into it
  //   const popup = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  //   if (popup) {
  //     popup.document.write(`
  //       <html>
  //         <head>
  //           <title>Print</title>
  //           <style>
  //             ${document.head.innerHTML}
  //           </style>
  //         </head>
  //         <body onload="window.print(); window.close();">
  //           ${domElem.outerHTML}
  //         </body>
  //       </html>
  //     `);
  //     popup.document.close();

  //     // Cleanup
  //     this.appRef.detachView(componentRef.hostView);
  //     componentRef.destroy();
  //   } else {
  //     console.error('Failed to open popup window');
  //   }
  // }


  // printComponent(title: string, content: string) {
  //   // Crear una nueva ventana en el DOM
  //   const printContents = `
  //     <html>
  //       <head>
  //         <title>Print</title>
  //         <style>
  //           /* Aquí puedes incluir tus estilos CSS */
  //         </style>
  //       </head>
  //       <body>
  //         <h1>${title}</h1>
  //         <div>${content}</div>
  //         <script>
  //           window.print();
  //           window.onafterprint = window.close;
  //         </script>
  //       </body>
  //     </html>
  //   `;

  //   const popupWindow = window.open('', '_blank', 'width=600,height=600');
  //   if (popupWindow) {
  //     popupWindow.document.open();
  //     popupWindow.document.write(printContents);
  //     popupWindow.document.close();
  //   } else {
  //     console.error('No se pudo abrir la ventana de impresión');
  //   }
  // }


printBoletaDeVentaA4(content: string) {
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0px';
    printFrame.style.height = '0px';
    printFrame.style.border = 'none';

    document.body.appendChild(printFrame);

    const printDocument = printFrame.contentDocument || printFrame.contentWindow?.document;
    if (printDocument) {
      printDocument.open();
      printDocument.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              *,
*::after,
*::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root{
    --blue-color: #0c2f54;
    --dark-color: #535b61;
    --white-color: #fff;
}

ul{
    list-style-type: none;
}
ul li{
    margin: 2px 0;
}

/* text colors */
.text-dark{
    color: var(--dark-color);
}
.text-blue{
    color: var(--blue-color);
}
.text-end{
    text-align: right;
}
.text-center{
    text-align: center;
}
.text-start{
    text-align: left;
}
.text-bold{
    font-weight: 700;
}
/* hr line */
.hr{
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
}
/* border-bottom */
.border-bottom{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

body{
    font-family: 'Poppins', sans-serif;
    color: var(--dark-color);
    font-size: 14px;
}
.invoice-wrapper{
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
}
.invoice{
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--white-color);
    min-height: 920px;
}
.invoice-head-top-left img{
    width: 130px;
}
.invoice-head-top-right h3{
    font-weight: 500;
    font-size: 27px;
    color: var(--blue-color);
}
.invoice-head-middle, .invoice-head-bottom{
    padding: 16px 0;
}
.invoice-body{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
}
.invoice-body table{
    border-collapse: collapse;
    border-radius: 4px;
    width: 100%;
}
.invoice-body table td, .invoice-body table th{
    padding: 12px;
}
.invoice-body table tr{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.invoice-body table thead{
    background-color: rgba(0, 0, 0, 0.02);
}
.invoice-body-info-item{
    display: grid;
    grid-template-columns: 80% 20%;
}
.invoice-body-info-item .info-item-td{
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.02);
}
.invoice-foot{
    padding: 30px 0;
}
.invoice-foot p{
    font-size: 12px;
}
.invoice-btns{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.invoice-btn{
    padding: 3px 9px;
    color: var(--dark-color);
    font-family: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 10px;
}

@media screen and (max-width: 992px){
    .invoice{
        padding: 40px;
    }
}

@media screen and (max-width: 576px){
    .invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
        grid-template-columns: repeat(1, 1fr);
    }
    .invoice-head-bottom-right{
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .invoice *{
        text-align: left;
    }
    .invoice{
        padding: 28px;
    }
}

.overflow-view{
    overflow-x: scroll;
}
.invoice-body{
    min-width: 600px;
}

@media print{
    .print-area{
        visibility: visible;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
    }

    @page {
    margin: 0;
  }

  body {
    margin: 1cm;
  }

  .no-print-header {
    display: none;
  }

    .overflow-view{
        overflow-x: hidden;
    }

    .invoice-btns{
        display: none;
    }
}

            </style>
            <link rel="stylesheet" type="text/css" href="./sv-pdf-invoice-a4.component.css">
          </head>
          <body>
            ${content}
          </body>
        </html>
      `);
      printDocument.close();

      printFrame.contentWindow?.focus();
      printFrame.contentWindow?.print();
    }

    setTimeout(() => {
      document.body.removeChild(printFrame);
    }, 1000);
  }



  printBoletaDeVentaTicketera(content: string) {
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0px';
    printFrame.style.height = '0px';
    printFrame.style.border = 'none';

    document.body.appendChild(printFrame);

    const printDocument = printFrame.contentDocument || printFrame.contentWindow?.document;
    if (printDocument) {
        printDocument.open();
        printDocument.write(`
            <html>
                <head>
                    <title>Print</title>
                    <link rel="stylesheet" type="text/css" href="/assets/sv-css/sv-pdf-ticket-deposito.css">
                </head>
                <body>
                    ${content}
                </body>
            </html>
        `);
        printDocument.close();

        printFrame.contentWindow?.focus();
        printFrame.contentWindow?.print();
    }

    setTimeout(() => {
        document.body.removeChild(printFrame);
    }, 1000);
  }



}
