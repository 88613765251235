import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloMatriculaService } from '../../modulo-matricula.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-matri-proceso-nuevo-estudiante',
  templateUrl: './matri-proceso-nuevo-estudiante.component.html',
  styleUrls: ['./matri-proceso-nuevo-estudiante.component.css']
})
export class MatriProcesoNuevoEstudianteComponent implements OnInit {
  alumnoForm: FormGroup;
  parentForm: FormGroup;
  tiposDocumento: any[] = [
    { id: 1, nombre: 'DNI' },
    { id: 2, nombre: 'Pasaporte' },
  ];

  constructor(
    private fb: FormBuilder,
    private moduloMatriculaService: ModuloMatriculaService,
    private router: Router,
  ) {
    this.alumnoForm = this.fb.group({
      name:                 ['', Validators.required],
      name_2:               [''],
      lastname_1:           ['', Validators.required],
      lastname_2:           ['', Validators.required],
      email:                ['', Validators.email],
      documento_identidad:  ['', Validators.required],
      tipo_documento:       ['', Validators.required],
      domicilio:            ['', Validators.required],
      celular:              ['', Validators.required],
    });

    const createParentForm = () => this.fb.group({
      id_user:              [''],
      name:                 ['', Validators.required],
      name_2:               [''],
      lastname_1:           ['', Validators.required],
      lastname_2:           ['', Validators.required],
      email:                ['', Validators.email],
      documento_identidad:  ['', Validators.required],
      tipo_documento:       ['', Validators.required],
      domicilio:            ['', Validators.required],
      celular:              ['', Validators.required],
    });

    this.parentForm = this.fb.group({
      padre: [false],
      madre: [false],
      apoderado: [false],
      padreForm: createParentForm(),
      madreForm: createParentForm(),
      apoderadoForm: createParentForm()
    });

    this.parentForm.get('padreForm')?.disable();
    this.parentForm.get('madreForm')?.disable();
    this.parentForm.get('apoderadoForm')?.disable();

  }

  ngOnInit(): void {}

  onSubmit() {
    if (!this.isFormValid()) {
      console.error('Form is not valid');
      return;
    }

    const processedParents = this.processParentData(this.parentForm.value);
    const dataForm = {
      usuario: this.alumnoForm.value,
      parientes: {
        ...this.parentForm.value,
        ...processedParents
      }
    };

    this.moduloMatriculaService.apiSaveUsers(dataForm).subscribe(
      (response: any) => {
        console.log(response);
        if (response && response.estudiante && response.estudiante.id) {
          this.router.navigate([`/modulos/matricula/estudiantes/${response.estudiante.id}/proceso`]);

        }
      }
    );
  }

  isFormValid(): boolean {
    if (this.alumnoForm.invalid) {
      return false;
    }

    const parentTypes = ['padre', 'madre', 'apoderado'];
    for (let type of parentTypes) {
      if (this.parentForm.get(type)?.value && this.parentForm.get(`${type}Form`)?.invalid) {
        return false;
      }
    }

    return true;
  }

  processParentData(parentData: any) {
    const processedData: { [key: string]: any } = {};
    ['padre', 'madre', 'apoderado'].forEach(parentType => {
      if (parentData[parentType]) {
        const formGroup = parentData[`${parentType}Form`];
        if (formGroup.id_user) {
          processedData[`${parentType}Form`] = { id_user: formGroup.id_user };
        } else {
          const { id_user, ...rest } = formGroup;
          processedData[`${parentType}Form`] = rest;
        }
      }
    });
    return processedData;
  }

  onParentChange(parentType: string) {
    const control = this.parentForm.get(parentType);
    if (control?.value) {
      this.parentForm.get(`${parentType}Form`)?.enable();
    } else {
      this.parentForm.get(`${parentType}Form`)?.disable();
    }
  }

}
