import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'sv-deposito-card-estudiante',
  templateUrl: './sv-deposito-card-estudiante.component.html',
  styleUrl: './sv-deposito-card-estudiante.component.css'
})
export class SvDepositoCardEstudianteComponent {

  @Input() xIdEstudiante: number | null = null;

  @Output() realizarDeposito = new EventEmitter<FormGroup>();
  @Output() depositoFormValueChanged = new EventEmitter<FormGroup>();

  depositoForm!: FormGroup;

  tiposComprobante: any[] = [
    { codigo: 'TKT', nombre: 'Ticket' },
    { codigo: 'BOL', nombre: 'Boleta' },
    { codigo: 'FAC', nombre: 'Factura' },
  ];
  tiposDeposito: any[] = [
    { codigo: 'EFEC',       nombre: 'Efectivo' },
    { codigo: 'YAPE',       nombre: 'Yape' },
    { codigo: 'PLIN',       nombre: 'Plin' },
    { codigo: 'BANCO-BCP',  nombre: 'Depósito Bancario - BCP' },
  ];

  constructor(
    private fb: FormBuilder,
  ) {
    this.depositoForm = this.fb.group({
      monto:            ['', Validators.required],
      tipo_comprobante: ['TKT', Validators.required],
      nro_receptor:     ['', Validators.required],
      tipo_deposito:    ['EFEC', Validators.required],
      nro_operacion:    [''],
      file:             [''],
    });
  }

  ngOnInit(): void {
    this.depositoFormValueChanged.emit(this.depositoForm);
    this.depositoForm.valueChanges.subscribe(() => {
      this.depositoFormValueChanged.emit(this.depositoForm);
    });
    this.depositoForm.get('tipo_deposito')?.valueChanges.subscribe(value => {
      this.toggleNroDepositoField(value);
    });
  }

  toggleNroDepositoField(tipoDeposito: string) {
    const nroOperacionControl = this.depositoForm.get('nro_operacion');
    const fileControl = this.depositoForm.get('file');

    if (tipoDeposito === 'EFEC') {
      nroOperacionControl?.clearValidators();
      nroOperacionControl?.setValue('');
      nroOperacionControl?.disable();

      fileControl?.clearValidators();
      fileControl?.setValue('');
      fileControl?.disable();
    } else {
      nroOperacionControl?.setValidators(Validators.required);
      nroOperacionControl?.enable();

      fileControl?.setValidators(Validators.required);
      fileControl?.enable();
    }
    nroOperacionControl?.updateValueAndValidity();
    fileControl?.updateValueAndValidity();
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.depositoForm.patchValue({
        file: file
      });
      this.depositoForm.get('file')?.updateValueAndValidity();
    }
  }

  onFileEvent(file: File | null) {
    if (file) {
      this.depositoForm.patchValue({ file });
    } else {
      this.depositoForm.patchValue({ file: null });
    }
    this.depositoForm.get('file')?.updateValueAndValidity();
  }

  confirmarDeposito() {
    if (this.depositoForm.valid) {
      this.realizarDeposito.emit(this.depositoForm);
    } else {
      this.depositoForm.markAllAsTouched();
    }
  }


}
